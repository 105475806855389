/*
 * Produced: Tue Dec 20 2022
 * Author: Alec M.
 * GitHub: https://amattu.com/links/github
 * Copyright: (C) 2022 Alec M.
 * License: License GNU Affero General Public License v3.0
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.generic-link {
  color: inherit;
  text-decoration: inherit;
}

.chat-history {
  height: calc(100% - 135px);
}

.chat-history ul {
  padding: 0;
}

.chat-history ul li {
  list-style: none;
  margin-bottom: 30px;
}

.chat-history ul li:last-child {
  margin-bottom: 0;
}

.chat-history .message-data {
  margin-bottom: 15px;
}

.chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}

.chat-history .message-data-time {
  color: #434651;
  padding-left: 6px;
}

.chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative;
}

.chat-history .message::after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}

.chat-history .my-message {
  background: #efefef;
}

.chat-history .my-message::after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px;
}

.chat-history .other-message {
  background: #e8f1f3;
  text-align: right;
}

.chat-history .other-message::after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.chat-message {
  padding: 20px;
}

.tab-content {
  overflow-y: auto;
}

.my-message blockquote {
  border-left: 8px solid;
  padding: 10px;
}
